<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent">
        <div class="my-card">
          <van-field
            v-model="viewData.Content"
            rows="4"
            maxlength="200"
            type="textarea"
            placeholder="输入您的意见..."
            :rules="[{ required: true }]"
          />
          <van-field name="accessoryList" label="">
            <template #input>
              <van-uploader
                v-model="viewData.accessoryList"
                :deletable="true"
                :preview-full-image="false"
                accept="image/*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>

        <van-button
          :loading="loading"
          block
          color="#47AFA7"
          type="info"
          native-type="submit"
          >提交</van-button
        >
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast,
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  data() {
    return {
      id: null,
      viewData: {
        accessoryList: [],
      },
      acceptUserList: [],
      peopleList: [],
      showPicker: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                this.viewData.accessoryList.push({
                  name: file.name,
                  osskey: res.data.osskey,
                  uploadUrl: res.data.uploadUrl,
                  url: res.data.url,
                });
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            this.viewData.accessoryList.push({
              name: file.name,
              osskey: res.data.osskey,
              uploadUrl: res.data.uploadUrl,
              url: res.data.url,
            });
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },

    save() {
      let that = this;
      let arr = [];
      this.$axios
        .post("/api/ParentMailbox/Add", {
          ...this.viewData,
          AccessoryPostList: this.viewData.accessoryList,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace({
                path: "MailboxForParentList",
              });
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
